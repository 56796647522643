import {
  Box,
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useDiscountApprovalStore } from "../../../../store/main/DiscountApprovalStore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toTitleCase } from "../../../../domain/Utils";
import { FetchEntityType } from "../../../../domain/models/discount-approvals/fetch-entity-type";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";
interface Action {
  productId: number;
  actionValue: string;
}
type Props = {
  customerData: FetchEntityType;
};

const DiscountApprovalsProductsTable: React.FC<Props> = ({ customerData }) => {
  const { couponRequestForCustomerList, handleConfirmModal } =
    useDiscountApprovalStore();

  return (
    <>
      <TableHead sx={{ backgroundColor: "#C7CED5" }}>
        <TableRow>
          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              {" "}
              Product
            </Typography>
          </TableCell>
          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              Organisation
            </Typography>
          </TableCell>
          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              MOQ
            </Typography>
          </TableCell>
          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              Discount As Per Slab
            </Typography>
          </TableCell>
          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              Discount
            </Typography>
          </TableCell>

          <TableCell align="center" sx={{ backgroundColor: "#C7CED5" }}>
            <Typography variant="subtitle2" fontWeight={660}>
              Actions
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {couponRequestForCustomerList.map((couponRequest, index: number) => (
          <TableRow key={index}>
            <TableCell align="center" component="th" scope="row">
              {couponRequest.variant_name}
            </TableCell>
            <TableCell align="center">
              {toTitleCase(couponRequest.org_code)}
            </TableCell>
            <TableCell align="center">{couponRequest.min_quantity}</TableCell>
            <TableCell align="center">
              {couponRequest.discount_as_per_slab}
            </TableCell>
            {couponRequest.status == "approved" ? (
              <TableCell align="center">
                <Typography display="inline" variant="body2" color={"GrayText"}>
                  {couponRequest.requested_discount}
                </Typography>
                {couponRequest.approved_discount && (
                  <ArrowForwardIcon
                    fontSize="small"
                    style={{ verticalAlign: "middle", margin: "0 4px" }}
                  />
                )}
                <Typography
                  display="inline"
                  variant="body2"
                  color={"green"}
                  fontWeight={600}
                >
                  {couponRequest.approved_discount}
                </Typography>
              </TableCell>
            ) : (
              <TableCell align="center" component="th" scope="row">
                {couponRequest.requested_discount}
              </TableCell>
            )}

            <TableCell align="center">
              {couponRequest.status !== "approved" ? (
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Tooltip title="Click to approve requested discount">
                    <Button
                      onClick={() =>
                        handleConfirmModal(true, {
                          customerId: customerData.customer_id,
                          action: "approve",
                          discountAsPerSlab: couponRequest.discount_as_per_slab,
                          requestId: couponRequest.id,
                          productName: couponRequest.product_name,
                          MOQ: couponRequest.min_quantity,
                          selectedDiscount: couponRequest.requested_discount,
                        })
                      }
                    >
                      <CheckCircleIcon color="success" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Click to edit requested discount">
                    <Button
                      onClick={() =>
                        handleConfirmModal(true, {
                          customerId: customerData.customer_id,
                          action: "edit",
                          discountAsPerSlab: couponRequest.discount_as_per_slab,
                          requestId: couponRequest.id,
                          productName: couponRequest.product_name,
                          MOQ: couponRequest.min_quantity,
                          selectedDiscount: couponRequest.requested_discount,
                        })
                      }
                    >
                      <EditIcon color="warning" />
                    </Button>
                  </Tooltip>
                </Stack>
              ) : (
                <Tooltip title={couponRequest.remarks}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      textTransform={"capitalize"}
                      variant="subtitle2"
                      color={"green"}
                      fontWeight={660}
                      mr={1}
                    >
                      {couponRequest.status}
                    </Typography>
                    <InfoIcon />
                  </Box>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
};

export default DiscountApprovalsProductsTable;
